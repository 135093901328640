
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Secret } from '../models/Secret'
import { AnxToastPlugin } from '@anexia/vue-ui-components'

/** Interface for a HTMLElement with copy function */
export interface CopyableHtmlElement extends HTMLElement {
  copy: () => boolean
}

@Component({})
export default class SecretCreated extends Vue {
  /** This is the url for the secret (only the last part of it) */
  @Prop({ default: '' }) url!: string;
  /** Show the secret above the link */
  @Prop({ default: null }) showSecret!: boolean;

  private showErrorAlert = false
  private showConfirmModal = false

  /** This is the url where the secret can be viewed */
  private get shortUrl () {
    return window.location.protocol + '//' + window.location.host + '/secret/' + this.url
  }

  /** This is the secret */
  private get secret () {
    return this.$store.state.secret.secret
  }

  /** Delete the secret */
  private async burn () {
    this.showConfirmModal = false
    try {
      await Secret.delete(this.url)
      this.$router.push({ name: 'secretDeleted' })
    } catch (e) {
      this.showErrorAlert = true
    }
  }

  /** Select and copy the text of the area with the specific id */
  private copy (copyRef: string) {
    try {
      if (this.$refs[copyRef]) {
        return (this.$refs[copyRef] as CopyableHtmlElement).copy()
      }
    } catch (e) {
      return false
    }
  }

  /** Shows a toast that says that the text has been copied to the clipboard */
  private showCopiedToast () {
    this.$anxToast.show(String(this.$t('message.c_copied_to_clipboard')), { verticalAlign: 'bottom', horizontalAlign: 'center' })
  }
}
